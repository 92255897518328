import React from "react";
import { Link } from "gatsby";
import { 
    Box,
    Image,
    Text,
    Badge,
    Stack,
    Spacer,
    Select,
    IconButton,
    Button,
    useInterval,
    Flex,
    Heading,
    Tooltip
} from "@chakra-ui/react";
import { RiDeleteBin6Line } from "react-icons/ri";

import { countryData } from "../media/country_data";

import Quantity from "./quantity";

import * as utils from "../utils/utility_funcs";
import UserMgr from "../utils/user_manager";
const UMObj = UserMgr.getInstance();


const getPackUnit = (props) => {
    if (!props.packUnit) return ["1 pc"];
    else if (props.packUnit.juiceSizes) return props.packUnit.juiceSizes;
    else return props.packUnit.weights;
};

const ShowcaseCard = (props) => {
    const prodOrigins = props.origin || [];
    const initStateOrig = prodOrigins.map(() => false);
    const [isLabelOpen, setIsLabelOpen] = React.useState(initStateOrig);
    const validPackUnits = getPackUnit(props);

    var parsedUnit = validPackUnits[0].split(" ")[1];
    var quantity = 0;
    var price = props.prices[0];
    const isDiscounted = (props.discount) ? props.discount > 0 : false;
    var discPrice = isDiscounted ? utils.getDiscountedPrice(price, props.discount) : price;

    const [selectState, updateSelectState] = React.useState("");
    const [selectedIdx, setSelectedIdx] = React.useState(0);
    const [isProductInCart, setIsProductInCart] = React.useState(false);
    const [isLoadingCart, setIsLoadingCart] = React.useState(false);
    const [pricesState, updatePrices] = React.useState([price, discPrice]);

    useInterval(() => {
        price = props.prices[selectedIdx];
        discPrice = isDiscounted ? utils.getDiscountedPrice(price, props.discount) : price;
        var appendedProps = utils.cloneJsonObj(props);

        appendedProps.select = selectedIdx;
        appendedProps.validUnit = validPackUnits;
        if (appendedProps.imageUrl) delete appendedProps.imageUrl;

        setIsProductInCart(UMObj.CartItemExists(appendedProps));
    }, 500);

    const handleSelect = (event) => {
        const selectedUnit = event.target.value;
        updateSelectState(selectedUnit);
        const selectIdx = validPackUnits.findIndex((element) => element === selectedUnit);
        setSelectedIdx(selectIdx);
        parsedUnit = selectedUnit.split(" ")[1];
        discPrice = isDiscounted ? utils.getDiscountedPrice(props.prices[selectIdx], props.discount) : props.prices[selectIdx];
        updatePrices([props.prices[selectIdx], discPrice])
    }

    const handleCartAdd = () => {
        setIsLoadingCart(true);

        var appendedProps = utils.cloneJsonObj(props);
        appendedProps.select = selectedIdx;
        appendedProps.quantity = parseInt(quantity);
        appendedProps.unit = parsedUnit;
        appendedProps.validUnit = validPackUnits;
        if (appendedProps.imageUrl) delete appendedProps.imageUrl;

        const success = UMObj.CartAdd(appendedProps);
        setIsProductInCart(success);

        setIsLoadingCart(false);
    }

    const handleCartRemove = () => {
        var appendedProps = utils.cloneJsonObj(props);
        appendedProps.select = selectedIdx;
        appendedProps.validUnit = validPackUnits;
        if (appendedProps.imageUrl) delete appendedProps.imageUrl;

        UMObj.CartRemove(appendedProps);
        setIsProductInCart(false);
    }

    const handleQuantityUpdate = (newQuantity) => {
        quantity = newQuantity;
    }

    return (
        <Flex
            mx={{ base: "0", md: "10%"}}
            w={{ base: "100%", md: "80%"}}
            flexDir={{ base: "column", md: "row"}}
            alignItems="center"
            borderWidth="1px"
            borderRadius="lg"
            borderColor = "#bec3c9"
            overflow="hidden"
            bgColor="lavender"
            boxShadow = "dark-lg"
        >
            <Box maxH="lg">
                <Image maxH="inherit" src={props.imageUrl} alt={props.name} fallbackSrc={props.imageUrlFB} />
            </Box>

            <Spacer />

            <Box 
                p={{base: "2", sm: "4", md: "8"}}
            >
                <Box d="flex" alignItems="baseline">
                    <Box
                        color="gray.500"
                        fontWeight="semibold"
                        letterSpacing="wide"
                        fontSize="xs"
                        textTransform="uppercase"
                    >
                        <Link to={"/categories/" + utils.removeWS(props.catName)}><u>{props.catName}</u></Link>
                        {" "}&bull;{" "}
                        <Link to={"/categories/" + utils.removeWS(props.catName) + "#" + utils.removeWS(props.subCatName)}><u>{props.subCatName}</u></Link>
                    </Box>
                </Box>

                <Heading size="lg">{props.name}</Heading>

                <Flex
                    mt={1}
                    display={props.description && props.description.length ? "flex" : "none"}
                    w="100%"
                    direction="row"
                    spacing={1}
                >
                    <Heading pt={1} size="xs">Description:</Heading>
                    <Text ml={2} fontSize="sm">{props.description}</Text>
                </Flex>

                <Flex
                    mt={1}
                    display={prodOrigins.length ? "flex" : "none"}
                    w="100%"
                    direction="row"
                    spacing={1}
                >
                    <Heading pt={1} size="xs">Origin:</Heading>

                    {
                        /* eslint-disable */
                        prodOrigins.map((country, i) => {
                            const c = [...countryData.filter(c => c.name.toUpperCase() === country.toUpperCase() || c.code === country)];
                            if (!c.length) return null;
                            return (
                                <Box pl={4} key={"origin_" + i}>
                                <Tooltip
                                    label={c[0].name}
                                    fontSize="md"
                                    isOpen={isLabelOpen[i]}>
                                    <span
                                        key={"origin_label" + i}
                                        role="img"
                                        aria-label={c[0].name}
                                        onMouseEnter={() => {
                                            const newState = utils.clone(initStateOrig);
                                            newState[i] = true;
                                            setIsLabelOpen(newState);
                                        }}
                                        onMouseLeave={() => {
                                            const newState = utils.clone(initStateOrig);
                                            newState[i] = false;
                                            setIsLabelOpen(newState);
                                        }}
                                        onClick={() => {
                                            const newState = utils.clone(initStateOrig);
                                            newState[i] = true;
                                            setIsLabelOpen(newState);
                                        }}
                                    >
                                        {c[0].emoji}
                                    </span>
                                </Tooltip>
                                </Box>
                            )
                        })
                        /* eslint-enable */
                    }
                </Flex>

                <Stack 
                    direction="row"
                    mt={2}
                    p={{base: 0, sm: 2}}
                    spacing={{base: 1, sm: 2}}
                    w="100%"
                >
                        <Text as="s" fontSize={{base: "md"}} display={isDiscounted}>
                            {isDiscounted ? pricesState[0] : ""}
                        </Text>
                        <Text fontSize={{base: "md"}}>{utils.formatPrice(pricesState[1])}</Text>
                        <Spacer />
                        <Badge
                            colorScheme="green"
                            h="fit-content"
                            visibility={isDiscounted ? "visible" : "hidden"}>{props.discount}% OFF</Badge>
                </Stack>

                <Stack 
                    direction={{ base: "column", sm: "row"}}
                    mt={2}
                >
                    <Select
                        value={selectState}
                        onChange={handleSelect}
                    >
                        {validPackUnits.map((p, i) => 
                            <option value={p} key={ i + "_" + props.name + "_" + p }>
                                {p}
                            </option>
                        )}
                    </Select>
                    <Quantity
                        moq={props.moq}
                        onChange={handleQuantityUpdate}
                    />
                </Stack>

                <Stack 
                    direction="row"
                    mt={3}
                >
                    <Button 
                        w="100%"
                        isLoading={isLoadingCart}
                        colorScheme="blue"
                        variant="solid"
                        onClick={handleCartAdd}
                        fontSize={{ base: "sm", sm: "lg"}}>
                        Add to Cart
                    </Button>
                    <IconButton
                        size="md"
                        variant="outline"
                        colorScheme="red"
                        aria-label={"Remove " + props.name + " from cart"}
                        icon={<RiDeleteBin6Line size={25}/>}
                        isDisabled={!isProductInCart}
                        onClick={handleCartRemove}
                    />
                </Stack>
            </Box>
        </Flex>
    )
};

export default ShowcaseCard;