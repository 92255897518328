import React from "react";
import { Box, IconButton } from "@chakra-ui/react";
import { Link } from "gatsby";
import { MdChevronLeft, MdHome } from "react-icons/md";

import { vitalizeConstants } from "../utils/constants";



const HomeButton = () => {
    return (
        <Box 
            position="fixed"
            zIndex={vitalizeConstants.Z_NAVBAR}
        >
            <Link to="/">
                <IconButton
                    variant="ghost"
                    colorScheme="teal"
                    aria-label="Go to Home"
                    icon={<><MdChevronLeft size={30} /><MdHome size={25} /></>}
                />
            </Link>
        </Box>
    )
}

export default HomeButton;