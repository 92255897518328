import React from "react";
import { graphql } from "gatsby";
import { Box, Heading } from "@chakra-ui/react";

import HomeButton from "./home";
import NavBar from "./navbar";
import ProductGrid from "./products";
import ShowcaseCard from "./showcase_card";
import Footer from "./footer";

import { getAnalyticsProduct } from "../utils/utility_funcs";
import { vitalizeConstants } from "../utils/constants";
import UserMgr from "../utils/user_manager"
const UMObj = UserMgr.getInstance();


const ProductView = (props) => {
    const mainProd = props.pageContext.main;
    const recProds = props.data.allContentfulProduct.nodes;

    // Log when user views this product (base price)
    UMObj.AnaLog("view_item", {
        currency: 'AED',
        value: mainProd.prices[0],
        items: [getAnalyticsProduct(mainProd.contentful_id, mainProd.name, mainProd.categoryAndSubCategory.categoryName, mainProd.prices[0], null)]
    })

    return (
        <Box as='main' bg='white'>
            <Box 
                position="sticky"
                top="0"
                margin={0}
                zIndex={vitalizeConstants.Z_NAVBAR}
            > 
                <NavBar />
            </Box>

            <HomeButton />
            <Box px={{base: "2", md: "6"}} py={10}>
                <ShowcaseCard
                    id={mainProd.contentful_id}
                    name={mainProd.name}
                    description={mainProd.description}
                    prices={mainProd.prices}
                    discount={mainProd.discount}
                    packUnit={mainProd.packunitSizes}
                    imageUrl={mainProd.image.file.url}
                    imageUrlFB={mainProd.imageLowRes.file.url}
                    catName={mainProd.categoryAndSubCategory.categoryName}
                    subCatName={mainProd.categoryAndSubCategory.subCategoryName}
                    moq={mainProd.moq}
                    origin={mainProd.origin}
                />
            </Box>
            <Box px={{base: "2", md: "6"}} py={4} >
                <Heading display={!recProds.length ? "none": "initial"}>Recommended Products</Heading>
                <Box mt={4}>
                <ProductGrid products={recProds}/>
                </Box>
            </Box>
            <Footer />
        </Box>
        )
}

export default ProductView;

export const productViewQuery = graphql`
query ($recommended: [String]) {
    allContentfulProduct (filter: {contentful_id: {in: $recommended}, name: {ne: "DummyProduct"}}) {
        nodes {
            id
            contentful_id
            name
            prices
            moq
            discount
            categoryAndSubCategory {
                contentful_id
                categoryName
                subCategoryName
            }
            imageLowRes {
                contentful_id
                file {
                    url
                }
            }
            packunitSizes {
                contentful_id
                juiceSizes
                weights
            }
        }
    }
}
`